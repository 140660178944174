<template>
  <div
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div v-if="showError" class="error-container mt-3">
      {{ showErrorMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      loadingText: "Authenticating, please wait...",
      showError: false,
      showErrorMessage:
        "You are not allowed to approve it. Please contact owner or login to workspace",
    };
  },
  mounted() {
    this.authenticateUser();
  },
  computed: {
    ...mapGetters("formbuilderData", ["getVerifyApprovalUser"]),
  },
  methods: {
    async authenticateUser() {
      this.loading = true;
      let params = {
        formbuilder_data_id: this.$route.query.formbuilder_data_id,
        ...(this.$route.query.company_user_id && {
          company_user_id: this.$route.query.company_user_id,
        }),
        ...(this.$route.query.application_user_id && {
          application_user_id: this.$route.query.application_user_id,
        }),
        ...(this.$route.query.relational_user_id && {
          relational_user_id: this.$route.query.relational_user_id,
        }),
        ...(this.$route.query.data_user_id && {
          data_user_id: this.$route.query.data_user_id,
        }),
        // ...(this.$route.query.approval_linked_field && {
        //   approval_linked_field: this.$route.query.approval_linked_field,
        // }),
      };
      if (window.location.href.includes("approval_linked_field")) {
        params.approval_linked_field = window.location.href.split("approval_linked_field=")[1];
      }
      await this.$store.dispatch(
        "formbuilderData/verifyApprovalUserAuth",
        params
      );
      if (this.getVerifyApprovalUser) {
        if (this.getVerifyApprovalUser?.valid) {
          this.$message.success("User verified Successfully");
          this.$router.push({
            name: "GuestFormbuilderApprovalExecute",
            params: {
              formbuilder_details_id: this.getVerifyApprovalUser.formbuilder_id,
            },
            query: {
              // ...query,
              ...{
                user_id: this.getVerifyApprovalUser.userDetails._id,
                formbuilder_data_id: this.getVerifyApprovalUser.formbuilder_data_id,
                step: 0,
              },
            },
          });
        } else {
          this.showError = true;
          this.showErrorMessage = this.getVerifyApprovalUser.message;
        }
      } else {
        this.showError = true;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.error-container {
  color: red;
  text-align: center;
  font-size: 20px;
}
</style>
